import { Pipe, PipeTransform } from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({name: 'translateHtml'})
export class TranslateHtmlPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(textToReplace: string = '', translateData: any): any
    {
        console.log("translateData =", translateData);
        const regExp = /@@([\s\S]*?)_translate@@/g;
        const regExp2 = /@@([\s\S]*?)@@/g;
        if (regExp.test(textToReplace)) {
            // parse the content.
            return textToReplace.replace(regExp, (_match, contents, _offset, _input_string) => {
                console.log("contents 1 ", contents);
                return this.getByKey(translateData, contents);
            });
        } else if (regExp2.test(textToReplace)) {
            // parse the content.
            return textToReplace.replace(regExp2, (_match, contents, _offset, _input_string) => {
                console.log("contents 2 ", contents);
                return this.getByKey(translateData, contents);
            });
        } else {
            return textToReplace;
        }
    }

    getByKey(recordData: any, contents: any) {
        if (objectPath.get(recordData, contents) !== undefined && objectPath.get(recordData, contents) !== null) {
            return objectPath.get(recordData, contents);
        } else if (recordData[contents] !== undefined && recordData[contents] !== null) {
            return recordData[contents];
        }
        return '';
    }
}
