import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from '../../pages/authentication/auth.service';
import { ComponentMapping } from '../../models/componentMapping.model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '../../global-component/input-dialog/input-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DescriptionPopupComponent } from '../../global-component/datatable/description-popup/description-popup.component';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SUPER_ADMIN_EMAILS } from '../../consts/superAdminConfig.const';
import { VoipCallComponent } from '../../apps/voip-call/voip-call/voip-call.component';
import * as introJs from 'intro.js/intro.js';
var HelpersService = /** @class */ (function () {
    function HelpersService(http, authService, dialog, snackBar, translate, toastrService, location) {
        var _this = this;
        this.http = http;
        this.authService = authService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.translate = translate;
        this.toastrService = toastrService;
        this.location = location;
        this.introJS = introJs();
        this.LOGIN_TIME_DIFF = 1000 * 60 * 60 * 8;
        this.REAUTH_TIME = 1000 * 60 * 60 * 6;
        this.SNACKBAR_DURATION = 2000;
        this.loading = false;
        this.translationData = {};
        this.loadingText = 'Loading...';
        this.getUserToken();
        setTimeout(function () {
            _this.getAllLangObj();
        }, 500);
        this.translate.onLangChange.subscribe(function (event) {
            _this.getAllLangObj();
        });
    }
    HelpersService.prototype.getUserToken = function () {
        if (JSON.parse(localStorage.getItem('ngStorage-token'))) {
            this.userToken = JSON.parse(localStorage.getItem('ngStorage-token'))['token'];
        }
        else {
            console.log('No token');
        }
    };
    HelpersService.prototype.getAllLangObj = function () {
        this.translationData = this.translate.translations[this.translate.currentLang] || {};
        console.log("this.translate ", this.translate, this.translate.translations);
    };
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 REGISTER HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
    * get register list
    */
    HelpersService.prototype.getRegisterList = function (queryParams) {
        var _this = this;
        if (queryParams === void 0) { queryParams = ''; }
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.notWorkspaceApis();
                // tslint:disable-next-line:max-line-length
                _this.getRequest("api/reg/register-list" + queryParams)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
     *
     * @param regId
     * get the register data from the regId
     */
    HelpersService.prototype.getRegisterData = function (regId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId) {
                _this.getRequest("api/reg/" + regId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regData
     * create new Register
     */
    HelpersService.prototype.createRegisterData = function (regData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.postRequest("api/reg/new", regData)
                .then(resolve).catch(reject);
        });
    };
    /**
     *
     * @param regId
     * @param regData
     * update register
     */
    HelpersService.prototype.updateRegisterData = function (regId, regData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && regData) {
                delete regData._id;
                _this.putRequest("api/reg/" + regId, regData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regId
     * delete register
     */
    HelpersService.prototype.deleteRegister = function (regId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId) {
                _this.deleteRequest("api/reg/" + regId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                REGISTER HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 REGISTER RECORD HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
    *
    * @param regId
    * @param recId
    * get the record data from the register record
    */
    HelpersService.prototype.getRegisterRecordData = function (regId, recId, extraHeader) {
        var _this = this;
        if (extraHeader === void 0) { extraHeader = {}; }
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.getRequest("api/reg/" + regId + "/" + recId, extraHeader)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regId
     * @param recData
     * create register record
     */
    HelpersService.prototype.createRegisterRecord = function (regId, recData, extraHeaders) {
        var _this = this;
        if (extraHeaders === void 0) { extraHeaders = {}; }
        return new Promise(function (resolve, reject) {
            if (regId) {
                delete recData['officeId'];
                delete recData['regId'];
                _this.postRequest("api/reg/" + regId + "/new", recData, extraHeaders)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regId
     * @param recId
     * @param recData
     * update register record
     */
    HelpersService.prototype.updateRegisterRecord = function (regId, recId, recData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                delete recData['officeId'];
                delete recData['regId'];
                _this.putRequest("api/reg/" + regId + "/" + recId, recData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    HelpersService.prototype.deleteRegisterRecord = function (regId, recId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.deleteRequest("api/reg/" + regId + "/" + recId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                REGISTER RECORD HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 PROCESS HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
     *
     * get process list
     */
    HelpersService.prototype.getProcessList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.notWorkspaceApis();
                // tslint:disable-next-line:max-line-length
                _this.getRequest("api/process.config/list?officeId=" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "&userId=%23%23%23ADMIN%23%23%23")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
     *
     * @param processId
     * get process data form the process id
     */
    HelpersService.prototype.getProcess = function (processId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (processId) {
                _this.notWorkspaceApis();
                _this.getRequest("api/process.config/" + processId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No processId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regId
     * @param recId
     * get the process buttons.
     */
    HelpersService.prototype.getProcessButtons = function (regId, recId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.getRequest("api/reg-process/" + regId + "/" + recId + "/getButtons")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
   *
   * @param regId
   * @param recId
   * @param buttonId
   * get the process activity data.
   */
    HelpersService.prototype.getProcessActivityData = function (regId, recId, buttonId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.postRequest("api/reg-process/" + regId + "/" + recId + "/" + buttonId, {})
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
   *
   * @param regId
   * @param recId
   * @param buttonId
   * get the process activity data.
   */
    HelpersService.prototype.getProcessActivityDataV2 = function (instanceId, buttonId, comment) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (instanceId) {
                _this.getRequest("api/reg-process/" + instanceId + "/" + buttonId + "?comment=" + comment)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    HelpersService.prototype.getProcessButtonByInstance = function (instanceId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (instanceId) {
                _this.getRequest("api/reg-process/" + instanceId + "/getButtons")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No instanceId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param processData
     * create new process
     */
    HelpersService.prototype.createProcess = function (processData) {
        this.notWorkspaceApis();
        return this.postRequest("api/process.config/new", processData);
    };
    /**
     *
     * @param processId
     * @param updatedData
     * update Process
     */
    HelpersService.prototype.updateProcess = function (processId, updatedData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (processId) {
                _this.notWorkspaceApis();
                _this.putRequest("api/process.config/" + processId, updatedData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No processId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param processId
     * delete process
     */
    HelpersService.prototype.deleteProcess = function (processId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (processId) {
                _this.notWorkspaceApis();
                _this.deleteRequest("api/process.config/" + processId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No processId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                PROCESS HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 INSTANCE HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
     *
     * @param processId
     * @param instanceId
     * get process instance data..
     */
    HelpersService.prototype.getProcessInstance = function (processId, instanceId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (processId && instanceId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/process/" + processId + "/instances/" + instanceId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No ProcessId or instanceId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
   *
   *
   * get process instance data..
   */
    HelpersService.prototype.getAllInstance = function (filter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                filter = filter || {};
                filter['officeId'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
                _this.postRequest("api/process/allInstances", filter)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
   *
   * @param processId
   * @param instanceId
   * get process instance data..
   */
    HelpersService.prototype.getAllRegisterRecord = function (filter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                filter = filter || {};
                filter['officeId'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
                _this.postRequest("api/reg/allRegRecord", filter)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                INSTANCE HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 FILES AND DOCUMENT HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
     *
     * @param documentId
     * get the document data..
     */
    HelpersService.prototype.getDocument = function (documentId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (documentId) {
                _this.getRequest("api/offices/files/" + documentId + "/getBase64File")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No documentId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param documentId
     * get the document data..
     */
    HelpersService.prototype.deleteDocument = function (documentId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                if (documentId) {
                    _this.deleteRequest("api/offices/files/" + documentId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No documentId provided. Please check configuration or consult admin about it');
                }
            }
            else {
                reject('No office found');
            }
        });
    };
    /**
   *
   * @param documentId
   * get the document data..
   */
    HelpersService.prototype.getSearchedFiles = function (params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    _this.http.get(environment.serverUrl + "api/offices/files/searchFile", { headers: headers, params: params })
                        .subscribe(function (data) {
                        _this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
                else {
                    // logout
                    console.log("666666");
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.getHttpHeaders = function (extraHeader) {
        if (extraHeader === void 0) { extraHeader = {}; }
        var headerObj = tslib_1.__assign({ 'Authorization': this.userToken, 'f-version': environment.version, 'lang': this.translate.defaultLang, 'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || '', 'virtualOrgId': JSON.parse(localStorage.getItem('selectedVirtualOrgId')) || '' }, extraHeader);
        if (localStorage.getItem('selected-workspace')) {
            headerObj['workspace'] = localStorage.getItem('selected-workspace') || '';
        }
        if (localStorage.getItem('wsAppData')) {
            var wsAppData = localStorage.getItem('wsAppData') || '';
            if (wsAppData) {
                wsAppData = JSON.parse(wsAppData);
                headerObj.workspaceIndex = wsAppData.stepNumber + "";
            }
        }
        if (localStorage.getItem('shared-token')) {
            headerObj['sharedToken'] = localStorage.getItem('shared-token') || '';
        }
        return new HttpHeaders(headerObj);
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                FILES AND DOCUMENT HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 SCANQUE HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                SCANQUE HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 FORM HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
     *
     * get the template list..
     */
    HelpersService.prototype.getFormList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/forms/list")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
     *
     * @param formId
     * GET the form from the form id
     */
    HelpersService.prototype.getForm = function (formId, key) {
        var _this = this;
        if (key === void 0) { key = ''; }
        return new Promise(function (resolve, reject) {
            if (formId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/forms/" + formId + key)
                        .then(resolve).catch(reject);
                }
                else {
                    _this.getRequest("api/offices/" + environment.GLOBAL_OFFICE_ID + "/forms/" + formId + key)
                        .then(resolve).catch(reject);
                }
            }
            else {
                reject('No form Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    // create form
    HelpersService.prototype.createForm = function (formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.postRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/forms/new", formData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
  *
  * @param formId
  * GET the form from the form id
  */
    HelpersService.prototype.updateForm = function (formId, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (formId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.putRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/forms/" + formId, formData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No form Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
    *
    * @param formId
    * GET the form from the form id
    */
    HelpersService.prototype.deleteForm = function (formId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (formId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.deleteRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/forms/" + formId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No form Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                FORM HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 TEMPLATE HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    /**
     *
     * get the template list..
     */
    HelpersService.prototype.getTemplateList = function (params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                params = params || '';
                _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/templates/list" + params)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
     *
     * @param templateId
     * get the template data..
     */
    HelpersService.prototype.getTemplate = function (templateId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (templateId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/templates/" + templateId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param templateId
     * get the template data..
     */
    HelpersService.prototype.getWebTemplate = function (templateId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (templateId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/web-templates/" + templateId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param templateData
     * create the template data..
     */
    HelpersService.prototype.createTemplate = function (templateData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (templateData) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.postRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/templates/new", templateData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
        *
        * @param templateData
        * update the template data..
        */
    HelpersService.prototype.updateTemplate = function (templateId, updatedTemplateData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (updatedTemplateData) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    // tslint:disable-next-line:max-line-length
                    _this.putRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/templates/" + templateId, updatedTemplateData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param templateData
     * create the template data..
     */
    HelpersService.prototype.createWebTemplate = function (templateData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (templateData) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.postRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/web-templates/new", templateData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
        *
        * @param templateData
        * update the template data..
        */
    HelpersService.prototype.updateWebTemplate = function (templateId, updatedTemplateData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (updatedTemplateData) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    // tslint:disable-next-line:max-line-length
                    _this.putRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/web-templates/" + templateId, updatedTemplateData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No templateId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
  *
  * @param formId
  * GET the form from the form id
  */
    HelpersService.prototype.deleteTemplate = function (templateId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (templateId) {
                if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                    _this.deleteRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/templates/" + templateId)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No office Id found');
                }
            }
            else {
                reject('No template Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                TEMPLATE HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /*
     ********************************************************************************************************************************
     ********************************************************************************************************************************
                                                 LINKS HELPERS STARTS
     ********************************************************************************************************************************
     ********************************************************************************************************************************
     */
    HelpersService.prototype.createNewLink = function (data) {
        return this.postRequest("api/links", data);
    };
    HelpersService.prototype.updateLink = function (linkId, data) {
        return this.putRequest("api/links/" + linkId, data);
    };
    HelpersService.prototype.deleteLink = function (linkId) {
        return this.deleteRequest("api/links/" + linkId);
    };
    /**----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
                                                LINKS HELPER ENDS
      -----------------------------------------------------------------------------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------------------------
     */
    /**
     *
     * get the profile data..
     */
    HelpersService.prototype.getProfileData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
                _this.getRequest("api/profile/" + JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'])
                    .then(resolve).catch(reject);
            }
            else {
                reject('No documentId provided. Please check configuration or consult admin about it');
            }
        });
    };
    // // update profile data 
    // updateProfileData(updatedData) {
    //   return new Promise((resolve, reject) => {
    //     if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
    //       this.http.patch(`${environment.serverUrl}api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`, updatedData)
    //       .subscribe((data: Response) => {
    //         this.buildBackendResponse(data, resolve, reject);
    //       }, err => this.buildHttpError(err, reject));
    //     } else {
    //       reject('No Profile id found');
    //     }
    //   });
    // }
    /**
     * updatedDoc: any
     * to update the office doc
     */
    HelpersService.prototype.updateOfficeDoc = function (updatedDoc) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.patchRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'], updatedDoc)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    // update the office doc in localstorage
    HelpersService.prototype.updateLocalStorageOfficeDoc = function (updatedDoc) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
            updatedDoc['_id'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(updatedDoc));
        }
    };
    HelpersService.prototype.pdfReport = function (getUrl, updatedData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    headers = headers.set('Accept', 'application/pdf');
                    if (getUrl && updatedData) {
                        _this.http.post("" + environment.serverUrl + getUrl, updatedData, { headers: headers, responseType: 'blob' })
                            .subscribe(function (data) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    //  ************************ OTHER HELPERS ********************************* //
    HelpersService.prototype.createScanque = function (scanqueData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    if (scanqueData) {
                        _this.http.post(environment.serverUrl + "api/scanqueue/new", scanqueData, { headers: headers })
                            .subscribe(function (data) {
                            resolve(data);
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.dataTableRequest = function (regId, params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    var headers = _this.getHttpHeaders();
                    if (regId && params) {
                        _this.http.post(environment.serverUrl + "api/reg/" + regId + "/data-table", params, { headers: headers })
                            .subscribe(function (data) {
                            if (data['success']) {
                                resolve(data);
                            }
                            else {
                                reject('Error Occurred while getting the data');
                            }
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    // type = 'form' or 'template'
    HelpersService.prototype.getFormOrTemplate = function (type, params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    if (params) {
                        var officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
                        _this.http.post(environment.serverUrl + "api/offices/" + officeId + "/" + type + "-dataTable", params, { headers: headers })
                            .subscribe(function (data) {
                            if (data['success']) {
                                resolve((data && data['result']) || {});
                            }
                            else {
                                reject('Error Occurred while getting the data');
                            }
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.getRequest = function (getUrl, extraHeader, skipAuth) {
        var _this = this;
        if (extraHeader === void 0) { extraHeader = {}; }
        if (skipAuth === void 0) { skipAuth = false; }
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess() || skipAuth) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = void 0;
                    if (!skipAuth) {
                        headers = _this.getHttpHeaders(extraHeader);
                    }
                    else {
                        headers = new HttpHeaders({});
                    }
                    _this.http.get("" + environment.serverUrl + getUrl, { headers: headers })
                        .subscribe(function (data) {
                        _this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
                else {
                    console.log("666666", getUrl);
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.getRequestWithParams = function (getUrl, paramsObj) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    var ops = {
                        headers: headers,
                        params: paramsObj
                    };
                    _this.http.get("" + environment.serverUrl + getUrl, ops)
                        .subscribe(function (data) {
                        _this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.patchRequest = function (getUrl, updatedData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    if (getUrl && updatedData) {
                        _this.http.patch("" + environment.serverUrl + getUrl, updatedData, { headers: headers })
                            .subscribe(function (data) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.putRequest = function (getUrl, updatedData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    //   if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    if (getUrl && updatedData) {
                        _this.http.put("" + environment.serverUrl + getUrl, updatedData, { headers: headers })
                            .subscribe(function (data) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.postRequest = function (getUrl, updatedData, extraHeaders) {
        var _this = this;
        if (extraHeaders === void 0) { extraHeaders = {}; }
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders(extraHeaders);
                    if (getUrl && updatedData) {
                        _this.http.post("" + environment.serverUrl + getUrl, updatedData, { headers: headers })
                            .subscribe(function (data) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }, function (err) { return _this.buildHttpError(err, reject); });
                    }
                    else {
                        reject('Error occurred while updating the document');
                    }
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    HelpersService.prototype.deleteRequest = function (deleteUrl) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unAuthProcess()) {
                    _this.getUserToken();
                    // if (!this.userToken) {
                    // }
                    var headers = _this.getHttpHeaders();
                    _this.http.delete("" + environment.serverUrl + deleteUrl, { headers: headers })
                        .subscribe(function (data) {
                        if (data && data['success']) {
                            resolve(data['success']);
                        }
                        else {
                            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                        }
                        // this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
                else {
                    console.log("666666");
                    // logout
                    _this.authService.logOut();
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    // update profile data 
    HelpersService.prototype.updateProfileData = function (updatedData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
                _this.patchRequest("api/profile/" + JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'], updatedData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No Profile id found');
            }
        });
    };
    HelpersService.prototype.unAuthProcess = function () {
        var loginTimeDiff = this.getLoginTimeDiff();
        if (loginTimeDiff !== null) {
            if (loginTimeDiff < this.LOGIN_TIME_DIFF && loginTimeDiff > this.REAUTH_TIME) {
                // reauth and refresh token..
                this.refreshToken();
                return true;
            }
            else if (loginTimeDiff > this.LOGIN_TIME_DIFF) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    };
    /**
 *
 * get register list
 */
    HelpersService.prototype.getSmallRegisterList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.getRequest("api/reg/register-list")
                    .then(resolve)
                    .catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    HelpersService.prototype.refreshToken = function () {
        return new Promise(function (resolve, reject) {
            resolve('Depreciated method');
        });
    };
    HelpersService.prototype.getLoginTimeDiff = function () {
        if (JSON.parse(localStorage.getItem('ngStorage-token')) && JSON.parse(localStorage.getItem('ngStorage-token'))['date']) {
            // tslint:disable-next-line:radix
            var loginTime = parseInt(JSON.parse(localStorage.getItem('ngStorage-token'))['date']);
            return new Date().getTime() - loginTime;
        }
        else {
            return null;
        }
    };
    /**
     *
     * @param data
     * @param resolve
     * @param reject
     * build the success or error message from the response
     */
    HelpersService.prototype.buildBackendResponse = function (data, resolve, reject) {
        if (data && data['success']) {
            if (data['result']) {
                resolve(data['result']);
            }
            else {
                reject('Response is empty. Please check the configuration');
            }
        }
        else {
            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
    };
    /**
     *
     * @param err
     * @param reject
     * build the error message from the http error
     */
    HelpersService.prototype.buildHttpError = function (err, reject) {
        // if (err && err['status'] && err['message']) {
        //   reject(err['status'] + '-' + err['message']);
        // } else {
        //   reject('Error Occured while fetching the data');
        // }
        reject((err && err.error && err.error.message) || err.message || 'Error Occured while fetching the data');
    };
    HelpersService.prototype.getTrans = function (comps) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.serverUrl + "api/i18n/langs", { componentName: (ComponentMapping[comps] || []) })
                .subscribe(function (data) {
                if (data && data['success']) {
                    if (data['result']) {
                        data = data['result'] || {};
                        var temp = [];
                        // tslint:disable-next-line:forin
                        for (var i in data) {
                            temp.push(data[i]);
                        }
                        _this.getAllLangObj();
                        resolve(temp);
                    }
                    else {
                        reject('Response is empty. Please check the configuration');
                    }
                }
                else {
                    reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                }
            }, function (err) { return _this.buildHttpError(err, reject); });
        });
    };
    HelpersService.prototype.getTransV2 = function (comps) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.serverUrl + "api/i18n/langs", { componentName: [comps] })
                .subscribe(function (data) {
                if (data && data['success']) {
                    if (data['result']) {
                        data = data['result'] || {};
                        var temp = [];
                        // tslint:disable-next-line:forin
                        for (var i in data) {
                            temp.push(data[i]);
                        }
                        _this.getAllLangObj();
                        resolve(temp);
                    }
                    else {
                        reject('Response is empty. Please check the configuration');
                    }
                }
                else {
                    reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                }
            }, function (err) { return _this.buildHttpError(err, reject); });
        });
    };
    /**
     *
     * @param docInfo
     *
     * download the document
     */
    HelpersService.prototype.downloadDocument = function (docInfo) {
        try {
            var blob = this.b64toBlob(docInfo['base64'], docInfo['mimetype'], null);
            var a = document.createElement('a');
            if (a.download !== undefined) {
                var blobUrl = URL.createObjectURL(blob);
                a.setAttribute('href', blobUrl);
                a.setAttribute('download', docInfo['originalname']);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
        catch (err) {
            console.info(err);
        }
    };
    /**
     * helper function for document download
     */
    HelpersService.prototype.b64toBlob = function (b64Data, contentType, sliceSize) {
        try {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;
            b64Data = b64Data || '';
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }
        catch (err) {
            console.info(err);
        }
    };
    HelpersService.prototype.openConfirmPopUp = function (message, isAutoClose, closeTime) {
        if (isAutoClose === void 0) { isAutoClose = false; }
        if (closeTime === void 0) { closeTime = 2000; }
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
            data: {
                isAutoClose: isAutoClose,
                closeTime: closeTime
            }
        });
        this.confirmDialogRef.componentInstance.confirmMessage = message;
        return this.confirmDialogRef.afterClosed();
    };
    HelpersService.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    HelpersService.prototype.openInputPopUp = function (placeholder, confirmMessage, inputVal, isRequired) {
        if (isRequired === void 0) { isRequired = false; }
        this.inputDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false,
            width: '350px',
            height: '130px',
            panelClass: 'happ-form-builder-dialog',
        });
        this.inputDialogRef.componentInstance.isRequired = isRequired;
        this.inputDialogRef.componentInstance.inputPlaceholder = placeholder;
        this.inputDialogRef.componentInstance.confirmMessage = confirmMessage;
        this.inputDialogRef.componentInstance.inputValue = inputVal;
        return this.inputDialogRef.afterClosed();
    };
    HelpersService.prototype.openTextareaPopUp = function (placeholder, confirmMessage, inputVal, isRequired) {
        if (isRequired === void 0) { isRequired = false; }
        this.inputDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false,
            width: '550px',
            height: '530px',
            panelClass: 'happ-form-builder-dialog',
        });
        this.inputDialogRef.componentInstance.inputType = "textarea";
        this.inputDialogRef.componentInstance.isRequired = isRequired;
        this.inputDialogRef.componentInstance.inputPlaceholder = placeholder;
        this.inputDialogRef.componentInstance.confirmMessage = confirmMessage;
        this.inputDialogRef.componentInstance.inputValue = inputVal;
        return this.inputDialogRef.afterClosed();
    };
    HelpersService.prototype.openSnackBar = function (message, actionMsg) {
        this.snackBar.open(message, actionMsg || 'Okay', {
            duration: 2000,
        });
    };
    HelpersService.prototype.openTranslatedSnackBar = function (transCompKey, message, actionMsgKey) {
        var msg = this.getTranslatedValue(transCompKey, message) || '';
        var actionMsg = this.getTranslatedValue(transCompKey, actionMsgKey) || 'Okay';
        this.snackBar.open(msg, actionMsg, {
            duration: this.SNACKBAR_DURATION,
        });
    };
    HelpersService.prototype.getTranslatedValue = function (transCompKey, transKey) {
        var langObj = this.translate.instant(transCompKey) || {};
        return langObj[transKey] || '';
    };
    // api/profile/workspaces
    HelpersService.prototype.getWorkspaceList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getRequest("api/profile/workspaces")
                .then(resolve).catch(reject);
        });
    };
    // create register record
    HelpersService.prototype.create_slug_RegisterRecord = function (regSlug, recData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var workspaceSlug = localStorage.getItem('selected-workspace');
            if (workspaceSlug) {
                if (regSlug) {
                    _this.postRequest("api/offices/work-space/" + workspaceSlug + "/reg/" + regSlug + "/new", recData)
                        .then(resolve).catch(reject);
                }
                else {
                    reject('No workspace present');
                }
            }
            else {
                reject('No regId provided. Please check configuration or consult admin about it');
            }
        });
    };
    // leave workspace....
    HelpersService.prototype.leaveWorkspace = function (wsSlug) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'] && wsSlug) {
                _this.deleteRequest("offices/work-space/:slug/users/" + JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'])
                    .then(resolve).catch(reject);
            }
            else {
                reject('No documentId provided. Please check configuration or consult admin about it');
            }
        });
    };
    // update register data
    HelpersService.prototype.updateWorkspaceData = function (wsSlug, wsData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (wsSlug) {
                delete wsData['_id'];
                _this.putRequest("api/offices/work-space/" + wsSlug, wsData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
 *
 * @param regId
 * get the redister data from the regId
 */
    HelpersService.prototype.getRegisterDataUsingSlug = function (regSlug) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regSlug) {
                _this.getRequest("api/offices/work-space/" + regSlug)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id provided. Please check configuration or consult admin about it');
            }
        });
    };
    HelpersService.prototype.getDefaultLangs = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            console.log("999999999");
            _this.http.get(environment.serverUrl + "api/i18n/langs")
                .subscribe(function (data) {
                // this.buildBackendResponse(data, resolve, reject);
                if (data && data['success']) {
                    if (data['result']) {
                        var temp = data['result'] || [];
                        temp = temp.filter(function (ele) { return ele.active; });
                        temp = temp.map(function (ele) {
                            ele['id'] = ele.code;
                            ele['title'] = ele.name;
                            ele['flag'] = ele.code;
                            return ele;
                        });
                        resolve(temp);
                    }
                    else {
                        reject('Response is empty. Please check the configuration');
                    }
                }
                else {
                    reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                }
            }, function (err) { return _this.buildHttpError(err, reject); });
        });
    };
    // delete register record
    /**
     *
     * @param regId
     * @param recId
     * get the record data from the register record
     */
    HelpersService.prototype.deleteRegisterRecordData = function (regId, recId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.deleteRequest("api/reg/" + regId + "/" + recId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No reg Id or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     *
     * @param regId
     * get the record data from the register record
     */
    HelpersService.prototype.getRegisterRecordDataV2 = function (recId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (recId) {
                _this.getRequest("api/registries/record/" + recId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
   *
   * get the main menu list..
   */
    HelpersService.prototype.getOfficeMainMenu = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.getRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/menu-settings")
                    .then(resolve).catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    // update register record
    HelpersService.prototype.saveAndNewRegisterRecord = function (regId, recId, recData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (regId && recId) {
                _this.putRequest("api/reg/" + regId + "/" + recId + "/save-and-new", recData)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No regId or recId provided. Please check configuration or consult admin about it');
            }
        });
    };
    /**
     * get files.
    */
    HelpersService.prototype.getFiles = function (filters) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                // tslint:disable-next-line:max-line-length
                _this.getRequest("api/offices/files/list?officeId=" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + filters)
                    .then(resolve)
                    .catch(reject);
            }
            else {
                reject('No office Id found');
            }
        });
    };
    /**
     * upload files
    */
    HelpersService.prototype.uploadFiles = function (filters, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                var profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;
                if (profile) {
                    var officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
                    var creatorId = profile['profileId'];
                    var creatorLogin = profile['email'];
                    // tslint:disable-next-line:max-line-length
                    _this.postRequest("api/offices/files/upload?creatorId=" + creatorId + "&creatorLogin=" + creatorLogin + "&officeId=" + officeId + filters, formData)
                        .then(resolve)
                        .catch(reject);
                }
                else {
                    reject('No profile found');
                }
            }
            else {
                reject('No office Id found');
            }
        });
    };
    HelpersService.prototype.getScanqueList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.getRequest("api/scanqueue/officeId/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/list")
                    .then(resolve)
                    .catch(reject);
            }
            else {
                reject('No office id');
            }
        });
    };
    HelpersService.prototype.notWorkspaceApis = function () {
        // localStorage.removeItem('selected-workspace');
    };
    HelpersService.prototype.getFormFlatField = function (obj) {
        var ng5AllFields = [];
        obj = obj || [];
        obj.forEach(function (tabValue) {
            if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                tabValue.tabContent.forEach(function (value) {
                    if (value.field) {
                        ng5AllFields.push(value);
                    }
                    if (value.children && typeof value.children === 'object') {
                        value.children.forEach(function (childValue) {
                            if (childValue.field) {
                                ng5AllFields.push(childValue);
                            }
                            if (childValue && typeof childValue === 'object') {
                                childValue.forEach(function (nestedChildValue) {
                                    if (nestedChildValue.field) {
                                        ng5AllFields.push(nestedChildValue);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return ng5AllFields;
    };
    HelpersService.prototype.getBrowserInfo = function () {
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;
        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browserName = 'Microsoft Internet Explorer';
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browserName = 'Chrome';
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browserName = 'Safari';
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browserName = 'Firefox';
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        if ((ix = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return {
            browserName: browserName,
            fullVersion: fullVersion,
            majorVersion: majorVersion,
            appName: navigator.appName,
            userAgent: navigator.userAgent
        };
    };
    HelpersService.prototype.transformSort = function (array, field) {
        if (!array || array && array.length === 0) {
            return [];
        }
        array.sort(function (a, b) {
            if (a && b && a[field] < b[field]) {
                return -1;
            }
            else if (a && b && a[field] > b[field]) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return array;
    };
    HelpersService.prototype.transformSortDec = function (array, field) {
        if (!array || array && array.length === 0) {
            return [];
        }
        array.sort(function (a, b) {
            if (a && b && a[field] < b[field]) {
                return 1;
            }
            else if (a && b && a[field] > b[field]) {
                return -1;
            }
            else {
                return 0;
            }
        });
        return array;
    };
    HelpersService.prototype.openInstructionPopup = function (value, type) {
        type = type || 'data';
        var dialogRef = this.dialog.open(DescriptionPopupComponent, {
            width: '60%',
            height: '60%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { type: type, value: value || '' }
        });
    };
    HelpersService.prototype.isNotEmpty = function (toCheck) {
        if (toCheck !== null && toCheck !== undefined) {
            return true;
        }
        else {
            return false;
        }
    };
    HelpersService.prototype.rowCalculateOnSave = function (fields, data) {
        var element2;
        if (fields['fields']) {
            data[fields['fields'][0]['operand']] = data[fields['fields'][0]['operand']] || 0;
            if ((parseFloat(data[fields['fields'][0]['operand']]) !== NaN)) {
                element2 = parseFloat(data[fields['fields'][0]['operand']]);
            }
            // tslint:disable-next-line:forin
            for (var j = 0; j < fields['fields'].length; j++) {
                var k = (j + 1);
                switch (fields['fields'][j]['operator']) {
                    case '+':
                        if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                            element2 += parseFloat(data[fields['fields'][j + 1]['operand']]);
                        }
                        break;
                    case '-':
                        if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                            element2 -= parseFloat(data[fields['fields'][j + 1]['operand']]);
                        }
                        break;
                    case '*':
                        if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                            element2 *= parseFloat(data[fields['fields'][j + 1]['operand']]);
                        }
                        break;
                    case '/':
                        if (data && data[fields['fields'][j + 1]['operand']] && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                            if (data[fields['fields'][j + 1]['operand']] !== 0 && parseFloat(data[fields['fields'][j + 1]['operand']]) !== 0) {
                                element2 /= parseFloat(data[fields['fields'][j + 1]['operand']]);
                            }
                        }
                        break;
                    case '%':
                        if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                            element2 = element2 * (parseFloat(data[fields['fields'][j + 1]['operand']]) / 100);
                            // if (parseFloat(data[fields['fields'][j + 1]['operand']]) !== 0) {
                            // } else {
                            //   element2
                            // }
                        }
                        break;
                    default:
                }
            }
        }
        else {
            element2 = 0;
        }
        return parseFloat(parseFloat(element2).toFixed(2));
        // if (this.isFloat(element2)) {
        // } else {
        //   return element2;
        // }
    };
    HelpersService.prototype.isInt = function (n) {
        return Number(n) === n && n % 1 === 0;
    };
    HelpersService.prototype.isFloat = function (n) {
        return Number(n) === n && n % 1 !== 0;
    };
    HelpersService.prototype.getXTypeVariables = function (obj, control_type, all) {
        var ng5AllFields = [];
        obj = obj || [];
        obj.forEach(function (tabValue) {
            if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                tabValue.tabContent.forEach(function (value) {
                    if (value.data !== null && (value.control_type === control_type || all)) {
                        ng5AllFields.push(value);
                    }
                    if (value.children && typeof value.children === 'object') {
                        value.children.forEach(function (childValue) {
                            if (childValue.data !== null && (childValue.control_type === control_type || all)) {
                                ng5AllFields.push(childValue);
                            }
                            if (childValue && typeof childValue === 'object') {
                                childValue.forEach(function (nestedChildValue) {
                                    // tslint:disable-next-line:max-line-length
                                    if (nestedChildValue.data !== null && (nestedChildValue.control_type === control_type || all)) {
                                        ng5AllFields.push(nestedChildValue);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return ng5AllFields;
    };
    HelpersService.prototype.showInfo = function (message, title, type, options) {
        if (title === void 0) { title = ''; }
        if (type === void 0) { type = 'info'; }
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!message) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.translate.get(message).toPromise()];
                    case 1:
                        message = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!title) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.translate.get(title).toPromise()];
                    case 3:
                        title = _a.sent();
                        _a.label = 4;
                    case 4:
                        this.toastrService[type](message, title, options);
                        return [2 /*return*/];
                }
            });
        });
    };
    HelpersService.prototype.getFullRegisterRecordList = function (regId, extraHeader, filters) {
        if (extraHeader === void 0) { extraHeader = {}; }
        if (filters === void 0) { filters = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var query_1, tt, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        query_1 = '';
                        if (Object.keys(filters).length) {
                            query_1 = '?';
                            Object.keys(filters).forEach(function (ele) {
                                query_1 = "" + query_1 + ele + "=" + filters[ele];
                            });
                        }
                        return [4 /*yield*/, this.getRequest("api/reg/" + regId + "/list" + query_1, extraHeader)];
                    case 1:
                        tt = _a.sent();
                        return [2 /*return*/, tt || []];
                    case 2:
                        err_1 = _a.sent();
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HelpersService.prototype.getDataForOptionBasedField = function (regId, columns, mqFilter, sort) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sortObj, tt, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        sortObj = {};
                        sortObj[sort || '_id'] = -1;
                        return [4 /*yield*/, this.postRequest("api/reg/" + regId + "/list", { columns: columns, hardFilter: mqFilter || '', sort: sortObj, limit: 200 })];
                    case 1:
                        tt = _a.sent();
                        return [2 /*return*/, (tt) || []];
                    case 2:
                        err_2 = _a.sent();
                        throw err_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HelpersService.prototype.checkMqFilter = function (mqFilter) {
        return this.postRequest("api/offices/forms/checkMqFilter", { mqFilter: mqFilter });
    };
    HelpersService.prototype.handleHttpError = function (error) {
        if (error === void 0) { error = {}; }
        switch (error.status) {
            case 401:
                this.openSnackBar("Not logged in or the login has expired, please log in again.", "");
                break;
            case 403:
            case 404:
            case 500:
                this.openSnackBar("" + ((error && error.error && error.error.message) || error.message));
                break;
            default:
                if (error instanceof HttpErrorResponse) {
                    this.openSnackBar('I don\'t know the error, most of it is caused by the backend not supporting CORS or invalid configuration.');
                }
                break;
        }
    };
    HelpersService.prototype.getFuseConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var officeData, profileData_1, officeSettings, profileSettings_1;
            return tslib_1.__generator(this, function (_a) {
                try {
                    officeData = {};
                    profileData_1 = {};
                    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                        officeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
                    }
                    else {
                        throw new Error('No Office Doc Found. Please Make Sure you have some Office selected');
                    }
                    if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
                        profileData_1 = JSON.parse(localStorage.getItem('ngStorage-profile'));
                    }
                    else {
                        throw new Error('No profile Found. Please login first');
                    }
                    officeSettings = officeData.aotFuseSetting || {};
                    profileSettings_1 = {};
                    officeData.users = officeData.users || [];
                    officeData.users.forEach(function (user) {
                        if (user && profileData_1 && profileData_1.profileId && profileData_1.profileId === user.id) {
                            profileSettings_1 = user.aotFuseSetting || {};
                        }
                    });
                    return [2 /*return*/, Object.assign(officeSettings, profileSettings_1)];
                }
                catch (error) {
                    throw error;
                }
                return [2 /*return*/];
            });
        });
    };
    HelpersService.prototype.goBack = function () {
        this.location.back();
    };
    HelpersService.prototype.getCurrentLocalWorkspace = function () {
        return (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
    };
    HelpersService.prototype.getAllRoutesParams = function (route) {
        var tempParams = JSON.parse(JSON.stringify(route.params));
        Object.keys(route.parent.params).forEach(function (key) {
            tempParams[key] = route.parent.params[key];
        });
        return tempParams;
    };
    HelpersService.prototype.checkSuperAdmin = function () {
        if (JSON.parse(localStorage.getItem('ngStorage-token')) &&
            JSON.parse(localStorage.getItem('ngStorage-token'))['username'] &&
            SUPER_ADMIN_EMAILS.includes(JSON.parse(localStorage.getItem('ngStorage-token'))['username'])) {
            return true;
        }
        else {
            return false;
        }
        return false;
    };
    HelpersService.prototype.getActiveLangs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getRequest("api/i18n/langs", {}, true)];
                    case 1:
                        data = _a.sent();
                        data = data || [];
                        data = data.filter(function (ele) { return (ele && ele.active); });
                        return [2 /*return*/, data];
                    case 2:
                        err_3 = _a.sent();
                        throw err_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HelpersService.prototype.formatTheLanguageArray = function (data) {
        if (data === void 0) { data = []; }
        return data.map(function (ele) {
            if (ele) {
                return {
                    id: ele.code,
                    title: ele.name,
                    flag: ele.code
                };
            }
        });
    };
    HelpersService.prototype.makeSharedServiceRequest = function (reqType, sharedOfficeId, route, body) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var headers = void 0;
                if (localStorage.getItem('selected-workspace')) {
                    headers = new HttpHeaders({
                        'Authorization': _this.userToken,
                        'f-version': environment.version,
                        'sharedOfficeId': sharedOfficeId,
                        'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || '',
                        'workspace': localStorage.getItem('selected-workspace') || ''
                    });
                }
                else {
                    headers = new HttpHeaders({
                        'Authorization': _this.userToken,
                        'f-version': environment.version,
                        'sharedOfficeId': sharedOfficeId,
                        'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || ''
                    });
                }
                if (reqType === 'get' || reqType === 'delete') {
                    _this.http[reqType]("" + environment.serverUrl + route, { headers: headers })
                        .subscribe(function (data) {
                        if (data && data['success']) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }
                        else {
                            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                        }
                        // this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
                else {
                    _this.http[reqType]("" + environment.serverUrl + route, body, { headers: headers })
                        .subscribe(function (data) {
                        if (data && data['success']) {
                            _this.buildBackendResponse(data, resolve, reject);
                        }
                        else {
                            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                        }
                        // this.buildBackendResponse(data, resolve, reject);
                    }, function (err) { return _this.buildHttpError(err, reject); });
                }
            }
            catch (error) {
                throw error;
            }
        });
    };
    /**
   * Helper to replace the variable (##INVIATION_TOKEN##) using the register record.
   *
   * @param  {string=""} htmlString
   * @param  {any} registerRecord
   * @returns string
   */
    HelpersService.prototype.replaceTheVariables = function (htmlString, registerRecord) {
        if (htmlString === void 0) { htmlString = ''; }
        try {
            if (registerRecord && typeof (htmlString) === "string") {
                htmlString = htmlString.replace(/##([\s\S]*?)##/g, function (match, contents, _offset, _inputString) {
                    return registerRecord[contents] || '';
                });
                return htmlString;
            }
            else {
                return htmlString;
            }
        }
        catch (err) {
            throw err;
        }
    };
    //  ************************ OTHER HELPERS ENDS ********************************* //
    HelpersService.prototype.openCallPopup = function (data) {
        data.helperInst = this;
        var dialogRef = this.dialog.open(VoipCallComponent, {
            width: '100%',
            height: '100%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: data
        });
        return dialogRef;
    };
    HelpersService.prototype.getAboutOffice = function (officeId, query) {
        if (query === void 0) { query = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryStr, temp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryStr = '?';
                        if (Object.keys(query)) {
                            Object.keys(query).forEach(function (ele) {
                                queryStr = queryStr + "&" + ele + "=" + query[ele];
                            });
                        }
                        return [4 /*yield*/, this.getRequest("api/external-page/" + officeId + ".pages.pinbox24.com" + queryStr)];
                    case 1:
                        temp = _a.sent();
                        return [2 /*return*/, temp];
                }
            });
        });
    };
    HelpersService.prototype.makeRequest = function (method) {
        this.http[method].subscribe(function (data) {
        });
    };
    HelpersService.prototype.setIntroJson = function (steps) {
        if (steps === void 0) { steps = []; }
        this.introJS.setOptions({
            steps: steps || [
                {
                    element: '#step1',
                    intro: 'Welcome to your new app!',
                    position: 'bottom'
                },
                {
                    element: '#step2',
                    intro: "Ok, wasn't that fun?",
                    position: 'right'
                },
                {
                    element: '[aria-label="open_office_dropdown"]',
                    intro: "let's keep going",
                    position: 'top'
                },
                {
                    element: '#step4',
                    intro: 'More features, more fun.',
                    position: 'right'
                }
            ],
            showProgress: true
        });
    };
    HelpersService.prototype.startIntro = function () {
        // this.introJS.start();
    };
    return HelpersService;
}());
export { HelpersService };
