import { INg5courseSetting } from 'app/main/content/interfaces/register.interface';
import { DescriptionEditComponent } from 'app/main/content/components/description-edit/description-edit.component';
import { SelectFormPopupComponent } from 'app/main/content/components/select-form-popup/select-form-popup.component';
import { CardActionsComponent } from '../ws-apps-settings-components/card-actions/card-actions.component';
import { HardFilterSetupComponent } from 'app/main/content/components/hard-filter-setup/hard-filter-setup.component';
import { SelectWorkspaceAppComponent } from 'app/main/content/components/select-workspace-app/select-workspace-app.component';
import * as objectPath from 'object-path';
import { DtTableAdvanceFilterComponent } from 'app/main/content/global-component/drag-and-drop/dt-table-advance-filter/dt-table-advance-filter.component';
import { DataTableColumnSettingsComponent } from 'app/main/content/components/data-table-column-settings/data-table-column-settings.component';
import { WsTemplateSelectionComponent } from 'app/main/content/components/ws-template-selection/ws-template-selection.component';

export class AdvanceSettings {
    general = [
        {
          'type': 'field',
          'settings':  {
            updateApi: '',
            fields: [
              // {
              //   fieldType: 'input',
              //   title: 'workspace.signature',
              //   description: 'workspace.signature_desc',
              //   initValue: '',
              //   defaultValue: '',
              //   keyName: 'reg_symbol',
              //   accessLevel: 'user'
              // },
              // {
              //   fieldType: 'select',
              //   title: 'workspace.reset_signature_number',
              //   description: 'workspace.reset_signature_number_desc',
              //   initValue: '2',
              //   defaultValue: 'ss/yy/nn',
              //   keyName: 'reg_signature_pattern',
              //   accessLevel: 'user',
              //   options: [
              //     {
              //       title: 'workspace.yearly',
              //       value: 'ss/yy/nn'
              //     },
              //     {
              //       title: 'workspace.monthly',
              //       value: 'ss/yy/mm/nn'
              //     },
              //     {
              //       title: 'workspace.daily',
              //       value: 'ss/yy/mm/dd/nn'
              //     },
              //   ]
              // },
              {
                fieldType: 'popup',
                title: 'workspace.dt_table_form',
                description: 'workspace.dt_table_form_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'formId',
                accessLevel: 'user',
                extraData: { allowSelection: true },
                component: SelectFormPopupComponent,
                buttonTitle: 'workspace.select_form',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'popup',
                title: 'workspace.card_actions',
                description: 'workspace.card_actions_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'cardActions',
                accessLevel: 'user',
                extraData: { fieldType: 'editor' },
                component: CardActionsComponent,
                buttonTitle: 'workspace.card_action',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'popup',
                title: 'workspace.copy_config',
                description: 'workspace.copy_config_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'workspaceSlug',
                accessLevel: 'user',
                extraData: { fieldType: 'editor' },
                component: SelectWorkspaceAppComponent,
                buttonTitle: 'workspace.copy_config',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'popup',
                title: 'workspace.view_filter',
                description: 'workspace.view_filter_desc',
                initValue: [],
                defaultValue: [],
                keyName: 'viewFilters',
                accessLevel: 'user',
                component: DtTableAdvanceFilterComponent,
                buttonTitle: 'workspace.view_filter',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'popup',
                title: 'workspace.select_template',
                description: 'workspace.select_template_desc',
                initValue: [],
                defaultValue: [],
                keyName: 'templateList',
                accessLevel: 'user',
                component: WsTemplateSelectionComponent,
                buttonTitle: 'workspace.select_template',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'checkbox',
                title: 'workspace.use_ws_dt_table_settings',
                description: 'workspace.use_ws_dt_table_settings_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'useWsDtTableSettings',
                accessLevel: 'user'
              },
              {
                fieldType: 'popup',
                title: 'workspace.dt_table_columns',
                description: 'workspace.dt_table_columns_desc',
                initValue: [],
                defaultValue: [],
                keyName: 'dtTableColumns',
                accessLevel: 'user',
                component: DataTableColumnSettingsComponent,
                buttonTitle: 'workspace.dt_table_columns',
                height: '100%',
                width: '100%',
                filterString: 'useWsDtTableSettings = true'

              },
              {
                fieldType: 'checkbox',
                title: 'workspace.pull_all_data_to_view',
                description: 'workspace.pull_all_data_to_view_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'pullAllDataToView',
                accessLevel: 'user'
              },
              {
                fieldType: 'checkbox',
                title: 'workspace.allow_mailing_list',
                description: 'workspace.allow_mailing_list_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'allowMailingList',
                accessLevel: 'user'
              },
              {
                fieldType: 'checkbox',
                title: 'workspace.show_badge_on_button',
                description: 'workspace.show_badge_on_button_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'showBadge',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                title: 'workspace.no_of_record_to_pull',
                description: 'workspace.no_of_record_to_pull_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'noOfRecordToPull',
                type: 'number',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                title: 'workspace.default_value_in_record_create',
                description: 'workspace.default_value_in_record_create_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'defRecValue',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                title: 'workspace.default_value_in_record_admin_create',
                description: 'workspace.default_value_in_record_admin_create_desc',
                initValue: '',
                defaultValue: '',
                disabled: true,
                keyName: 'defRecAdminValue',
                accessLevel: 'user'
              },
              {
                fieldType: 'select',
                title: 'workspace.card_default_sort_field',
                description: 'workspace.card_default_sort_field_desc',
                initValue: '',
                defaultValue: 'registredDataTime',
                keyName: 'defaultCardSortField',
                accessLevel: 'user',
                options: [
                  {
                    title: 'workspace.register_date_time',
                    value: 'registredDataTime'
                  },
                  {
                    title: 'workspace.modificator_date_time',
                    value: 'modificatorDateTime'
                  }
                  // more to process.
                ]
              },
              {
                fieldType: 'select',
                title: 'workspace.card_default_sort_order',
                description: 'workspace.card_default_sort_order_desc',
                initValue: '',
                defaultValue: 'desc',
                keyName: 'defaultCardSortOrder',
                accessLevel: 'user',
                options: [
                  {
                    title: 'workspace.descending',
                    value: 'desc'
                  },
                  {
                    title: 'workspace.ascending',
                    value: 'asc'
                  }
                ]
              }
            ]
        }
      }
    ];

    filters = [
      {
        'type': 'field',
        'settings':  {
          updateApi: '',
          fields: [
            {
              fieldType: 'popup',
              title: 'workspace.hard_filter',
              description: 'workspace.hard_filter_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'hardFilter',
              accessLevel: 'user',
              component: HardFilterSetupComponent,
              height: '350px',
              width: '600px',
              buttonTitle: 'workspace.hard_filter',
              extraData: {}
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.show_hard_filter',
              description: 'workspace.show_hard_filter_desc',
              initValue: '',
              defaultValue: false,
              keyName: 'showHardFilter',
              accessLevel: 'user'
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.disable_multi_office_filter',
              description: 'workspace.show_hard_filter_desc',
              initValue: '',
              defaultValue: false,
              keyName: 'multiOfficeFiltrationDisabled',
              accessLevel: 'user'
            },
            {
              fieldType: 'select',
              title: 'workspace.hard_filter_toolbar_alignment',
              description: 'workspace.hard_filter_toolbar_alignment_desc',
              initValue: '',
              defaultValue: 'top',
              keyName: 'hardFilterConfig.align',
              accessLevel: 'user',
              options: [
                {
                  title: 'workspace.top',
                  value: 'top'
                },
                {
                  title: 'workspace.bottom',
                  value: 'bottom'
                },
                {
                  title: 'workspace.left',
                  value: 'left'
                }
              ]
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.show_date_time_filter',
              description: 'workspace.show_date_time_filter_desc',
              initValue: '',
              defaultValue: true,
              keyName: 'showDateRange',
              accessLevel: 'user'
            },
            {
              fieldType: 'date-filter',
              title: 'workspace.default_date_filter',
              description: 'workspace.default_date_filter_desc',
              initValue: '',
              defaultValue: 'customValue',
              keyName: 'defaultDateFilter',
              accessLevel: 'user'
            },
            {
              fieldType: 'input',
              title: 'workspace.date_range_filter_time_difference',
              description: 'workspace.date_range_filter_time_difference_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'dataRangeTimeDiff',
              accessLevel: 'user'
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.show_advance_filter',
              description: 'workspace.show_advance_filter_desc',
              initValue: '',
              defaultValue: false,
              keyName: 'noAdvanceFiltersSelected',
              accessLevel: 'user'
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.hide_date_range_filter',
              description: 'workspace.hide_date_range_filter_desc',
              initValue: '',
              defaultValue: false,
              keyName: 'hideDateRange',
              accessLevel: 'user'
            },
            {
              fieldType: 'checkbox',
              title: 'workspace.show_super_filter',
              description: 'workspace.show_super_filter_desc',
              initValue: '',
              defaultValue: false,
              keyName: 'showSuperFilter',
              accessLevel: 'user'
            },
          ]
      }
    }
  ];

  actions = [
    {
      'type': 'field',
      'settings':  {
        updateApi: '',
        fields: [
          // {
          //   fieldType: 'popup',
          //   title: 'workspace.scanque',
          //   description: 'workspace.scanque_desc',
          //   initValue: '',
          //   defaultValue: '',
          //   keyName: 'scanqueue',
          //   accessLevel: 'user',
          //   component: SelectIconComponent,
          //   height: '',
          //   width: '',
          //   buttonTitle: 'workspace.use_scanque',
          //   extraData: {}
          // },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_sub_actions',
            description: 'workspace.show_sub_actions_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'showSubAction',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.hide_header_actions',
            description: 'workspace.hide_header_actions_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'hideHeaderActions',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_postbook',
            description: 'workspace.show_postbook_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'showPostbook',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_calendar',
            description: 'workspace.show_calendar_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'isCalendar',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.hide_edit_button',
            description: 'workspace.hide_edit_button_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'hideEditButton',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.hide_action_column',
            description: 'workspace.hide_action_column_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'hideActionColumn',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_bulk_action_button',
            description: 'workspace.show_bulk_action_button_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'showBulkAction',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.default_process_status_view',
            description: 'workspace.default_process_status_view_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'isProcessStatusView',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.barcode_single',
            description: 'workspace.barcode_single_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'barcode_single',
            accessLevel: 'user',
          },
          {
            fieldType: 'popup',
            title: 'workspace.single_barcode_params',
            description: 'workspace.single_barcode_params_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'single_barcode_params',
            accessLevel: 'user',
            extraData: { fieldType: 'textArea' },
            component: DescriptionEditComponent,
            buttonTitle: 'workspace.single_barcode_params',
            height: '420px',
            width: '60%',
            filterString: 'barcode_single = true'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.barcode_package',
            description: 'workspace.barcode_package_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'barcode_package',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_scanque',
            description: 'workspace.show_scanque_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'showScanque',
            accessLevel: 'user'
          },
          {
            fieldType: 'popup',
            title: 'workspace.package_barcode_params',
            description: 'workspace.package_barcode_params_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'package_barcode_params',
            accessLevel: 'user',
            extraData: { fieldType: 'textArea' },
            component: DescriptionEditComponent,
            buttonTitle: 'workspace.package_barcode_params',
            height: '420px',
            width: '60%',
            filterString: 'barcode_package = true'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.create_new_record_on_add',
            description: 'workspace.create_new_record_on_add_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'createAndEdit',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.enable_custom_translation',
            description: 'workspace.enable_custom_translation_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'enableCustomTranslation',
            accessLevel: 'user'
          },
          {
            fieldType: 'input',
            title: 'workspace.custom_translation_module_key',
            description: 'workspace.custom_translation_module_key_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'translationModuleKey',
            accessLevel: 'user',

          },
        ]
    }
  }
  ];
  
  cardHeader = [
    {
      'type': 'field',
      'settings':  {
        updateApi: '',
        fields: [
          {
            fieldType: 'checkbox',
            title: 'workspace.show_sum',
            description: 'workspace.show_sum_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'cardHeader.showSum',
            accessLevel: 'user'
          },
          {
            fieldType: 'input',
            title: 'workspace.sum_key_name',
            description: 'workspace.sum_key_name_desc',
            initValue: '',
            defaultValue: 'estTime',
            keyName: 'cardHeader.sumKeyName',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.if_sum_zero_show_info',
            description: 'workspace.if_sum_zero_show_info_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'cardHeader.showZeroInfo',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true'

          },
          {
            fieldType: 'input',
            title: 'workspace.zero_info',
            description: 'workspace.zero_info_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'cardHeader.zeroInfo',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true AND cardHeader.showZeroInfo = true'

          },
          {
            fieldType: 'checkbox',
            title: 'workspace.divide_sum_result',
            description: 'workspace.divide_sum_result_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'cardHeader.isDivideSum',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true'

          },
          {
            fieldType: 'input',
            type: 'number',
            title: 'workspace.divide_sum_by',
            description: 'workspace.divide_sum_by_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'cardHeader.divideSumBy',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true AND cardHeader.isDivideSum'

          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_suffix',
            description: 'workspace.show_suffix_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'cardHeader.showSuffix',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true'

          },
          {
            fieldType: 'input',
            title: 'workspace.suffix',
            description: 'workspace.suffix_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'cardHeader.suffix',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true AND cardHeader.showSuffix = true'

          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_prefix',
            description: 'workspace.show_prefix_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'cardHeader.showPrefix',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true'

          },
          {
            fieldType: 'input',
            title: 'workspace.prefix',
            description: 'workspace.prefix_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'cardHeader.prefix',
            accessLevel: 'user',
            filterString: 'cardHeader.showSum = true AND cardHeader.showPrefix = true'

          }
        ]
    }
  }
  ];

   
  mobileAppSettings = [
    {
      'type': 'field',
      'settings':  {
        updateApi: '',
        fields: [
          {
            fieldType: 'checkbox',
            title: 'workspace.sort_record',
            description: 'workspace.sort_record_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'mobileSettings.sortRecord',
            accessLevel: 'user'
          },
          {
            fieldType: 'input',
            title: 'workspace.sort_by_field',
            description: 'workspace.sort_by_field_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'mobileSettings.sortField',
            accessLevel: 'user',
            filterString: 'mobileSettings.sortRecord = true'
          }
        ]
    }
  }
  ];

  qrScanSearch = [
    {
      'type': 'field',
      'settings':  {
        updateApi: '',
        fields: [
          {
            fieldType: 'checkbox',
            title: 'workspace.show_qr_search',
            description: 'workspace.show_qr_search_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'qrSettings.showScanner',
            accessLevel: 'user'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.show_all_record_on_init',
            description: 'workspace.show_all_record_on_init_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'qrSettings.showInitRecords',
            accessLevel: 'user',
            // filterString: 'qrSettings.showScanner = true'
          },
          {
            fieldType: 'checkbox',
            title: 'workspace.exact_search',
            description: 'workspace.exact_search_desc',
            initValue: '',
            defaultValue: false,
            keyName: 'qrSettings.exactSearch',
            accessLevel: 'user',
            // filterString: 'qrSettings.showScanner = true'
          },
          {
            fieldType: 'input',
            title: 'workspace.search_by_field',
            description: 'workspace.search_by_field_desc',
            initValue: '',
            defaultValue: '',
            keyName: 'qrSettings.searchByField',
            accessLevel: 'user',
            // filterString: 'qrSettings.showScanner = true'
          },
        ]
    }
  }
  ];

    settingJson = [
        {
            'type': 'section',
            'settings': [
                {
                  'title': 'workspace.general',
                  'children': [ ...this.general ]
                }
            ]
        },
        {
          'type': 'section',
          'settings': [
              {
                'title': 'workspace.filter',
                'children': [ ...this.filters ]
              }
          ]
        },
        {
          'type': 'section',
          'settings': [
              {
                'title': 'workspace.actions',
                'children': [ ...this.actions ]
              }
          ]
        },
        {
          'type': 'section',
          'settings': [
              {
                'title': 'workspace.card_header',
                'children': [ ...this.cardHeader ]
              }
          ]
        },
        {
          'type': 'section',
          'settings': [
              {
                'title': 'workspace.mobile_settings',
                'children': [ ...this.mobileAppSettings ]
              }
          ]
        },
        {
          'type': 'section',
          'settings': [
              {
                'title': 'workspace.qr_scanner_search',
                'children': [ ...this.qrScanSearch ]
              }
          ]
        }
    ];
    constructor(url: string, appData: INg5courseSetting = {} as any, moreInfo: any = {}) {
        this.settingJson.forEach((setting: any) => {
            setting.settings.forEach((sectionSetting: any) => {
                sectionSetting.children.forEach((fieldSetting: any) => {
                    fieldSetting.settings = fieldSetting.settings || {};
                    fieldSetting.settings.updateApi = url;
                    fieldSetting.settings['triggerSub'] = moreInfo.triggerSub;
                    fieldSetting.settings.fields = fieldSetting.settings.fields || [];
                    fieldSetting.settings.fields.forEach((field: any) => {
                        field['initValue'] = (objectPath.get(appData, field.keyName) || objectPath.get(appData, field.keyName) === false) ? 
                        objectPath.get(appData, field.keyName) : field['defaultValue'];
                        field['dialogRef'] = moreInfo.dialogRef;

                        if (field.fieldType === 'select') {
                          field.options = field.options || [];
                          field.options = field.options.concat(moreInfo[field.keyName] || []);
                        }
                        if (field.fieldType === 'popup') {
                          moreInfo[field.keyName] = moreInfo[field.keyName] || {};
                          field.extraData = field.extraData || {};
                          field.extraData.hardFilter = appData.hardFilter;
                          field.extraData = Object.assign(moreInfo[field.keyName], field.extraData);
                        }
                    });
                });
            });
        });
    }

    get settingsModel () {
        return this.settingJson;
    }
}
